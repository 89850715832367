export const LIB_NAME = "Directional Navigation";

export const SECTION_ID_ATTR = "data-section-id";

export const SECTION_DIRECTION_ATTR = "data-section-direction";

export const PARENT_SECTION_ATTR = "data-parent-section";

export const DIRECTION_NAVIGATE_ATTR = "data-navigate-{direction}";

export const SECTION_CURRENT_FOCUSED_CHILDREN_ATTR =
  "data-focused-children-index";

export const SECTION_GRID_COLUMN_ATTR = "data-grid-columns";

export const SECTION_GRID_ROW_ATTR = "data-grid-rows";

export const LEAVE_SECTION_DIRECTION_ATTR = "data-leave-{direction}";

export const LAST_COLUMN_PRODUCT_RESULT = 0;

export const FIRST_COLUMN_PRODUCT_RESULT = 1;

export const CHILDREN_INDEX_ATTR = "data-chidlren-index";
